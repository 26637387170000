@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Bangers';
    src: url('../fonts/bangers/Bangers-Regular.eot');
    src: url('../fonts/bangers/Bangers-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/bangers/Bangers-Regular.woff2') format('woff2'),
        url('../fonts/bangers/Bangers-Regular.woff') format('woff'),
        url('../fonts/bangers/Bangers-Regular.ttf') format('truetype'),
        url('../fonts/bangers/Bangers-Regular.svg#Bangers-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Delicious Handrawn';
    src: url('../fonts/delicioushandrawn/DeliciousHandrawn-Regular.eot');
    src: url('../fonts/delicioushandrawn/DeliciousHandrawn-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/delicioushandrawn/DeliciousHandrawn-Regular.woff2') format('woff2'),
        url('../fonts/delicioushandrawn/DeliciousHandrawn-Regular.woff') format('woff'),
        url('../fonts/delicioushandrawn/DeliciousHandrawn-Regular.ttf') format('truetype'),
        url('../fonts/delicioushandrawn/DeliciousHandrawn-Regular.svg#DeliciousHandrawn-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nanum Pen';
    src: url('../fonts/nanumpen/NanumPen.eot');
    src: url('../fonts/nanumpen/NanumPen.eot?#iefix') format('embedded-opentype'),
        url('../fonts/nanumpen/NanumPen.woff2') format('woff2'),
        url('../fonts/nanumpen/NanumPen.woff') format('woff'),
        url('../fonts/nanumpen/NanumPen.ttf') format('truetype'),
        url('../fonts/nanumpen/NanumPen.svg#NanumPen') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../fonts/roboto/RobotoCondensed-Italic.eot');
    src: url('../fonts/roboto/RobotoCondensed-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/RobotoCondensed-Italic.woff2') format('woff2'),
        url('../fonts/roboto/RobotoCondensed-Italic.woff') format('woff'),
        url('../fonts/roboto/RobotoCondensed-Italic.ttf') format('truetype'),
        url('../fonts/roboto/RobotoCondensed-Italic.svg#RobotoCondensed-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

.font-bangers {
    font-family: "Bangers";
}

.font-nanum {
    font-family: "Nanum Pen";
}

body {
    background-color: #070508;
    overflow: hidden;
}

.loader {
    z-index: 11111111111;
    background-color: #070508;
}

.header {
    background-color: rgb(0, 0, 0);
    opacity: 0.902;
    position: fixed;
    top: 0;
    height: 80px;
    width: 100%;
    left: 0;
    z-index: 1111111111;
}

@media (max-width: 768px) {
    .header {
        /* position: relative; */
        display: none;
    }
}

.header .item {
    font-size: 25px;
    font-family: "Bangers";
    color: rgb(250, 26, 250);
    text-transform: uppercase;
    padding-right: 60px;
    cursor: pointer;
}

.narrative {
    position: absolute;
    font-size: 30px;
    width: 450px;
    font-family: "Delicious Handrawn";
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    line-height: 0.889;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    bottom: -70px;
    left: calc(50% - 225px);
}

@media (max-width: 768px) {
    .narrative {
        width: 240px;
        left: calc(50% - 120px);
        font-size: 16px;
        bottom: -45px;
    }
}

.join {
    position: absolute;
    font-size: 40px;
    width: 250px;
    font-family: "Delicious Handrawn";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    line-height: 0.889;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    bottom: -118px;
    left: calc(50% - 125px);
}

.video-control-btn {
    width: 100px;
    cursor: pointer;
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}

.video-control-btn:hover {
    opacity: 1;
}

.join img {
    width: 100%;
}

.another-space {
    position: absolute;
    top: 40%;
    width: 1060px;
    left: calc(50% - 530px);
}

@media (max-width: 1400px) {
    .another-space {
        width: 800px;
        left: calc(50% - 400px);
    }
}

@media (max-width: 1000px) {
    .another-space {
        width: 600px;
        left: calc(50% - 300px);
    }
}

@media (max-width: 768px) {
    .join {
        /* display: none; */
        width: 120px;
        bottom: -68px;
        left: calc(50% - 60px);
        font-size: 20px;
    }

    .another-space {
        width: 80%;
        left: 10%;
    }
}

.embark-cosmic-journey {
    position: absolute;
    top: 70%;
    font-size: 30px;
    font-family: "Delicious Handrawn";
    text-transform: uppercase;
    text-align: center;
    width: 100%;
}

.hamburger {
    font-size: 30px;
    cursor: pointer;
    display: none;
}

.ham-dropdown {
    display: none;
}

@media (max-width: 768px) {
    .hamburger {
        display: block;
    }

    .menu-items {
        display: none;
    }

    .ham-dropdown {
        display: block;
        position: absolute;
        top: 80px;
        width: 100%;
        background-color: #0C0C0D;
        z-index: 5;
    }

    .ham-dropdown .item {
        font-size: 20px;
        text-align: center;
        font-family: "Bangers";
        color: rgb(250, 26, 250);
        text-transform: uppercase;
    }
}

.embark-cosmic-journey div {
    width: 80%;
    margin: auto;
    text-shadow: 2px 2px white;
}

@media (max-width: 900px) {
    .embark-cosmic-journey {
        font-size: 16px;
        padding: 10px;
        width: 100%;
    }

    .embark-cosmic-journey div {
        width: 100%;
    }
}

@media (max-width: 550px) {
    .embark-cosmic-journey {
        font-size: 11px;
    }
}

.video {
    background-image: url("../images/video-bg.png");
    background-size: cover;
    position: relative;
    z-index: 111;
}

.video>div {
    margin: auto;
    max-width: 1000px;
}

.video .text-join {
    /* font-size: 60px; */
    font-family: "Bangers";
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    padding-left: 10%;
    line-height: 0.9;
}

.video .description {
    font-size: 26px;
    font-family: "Delicious Handrawn";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    text-align: center;
    margin: auto;
    padding-top: 80px;
}

.video .item {
    padding: 20px;
}

.video .item video {
    border: solid 8px white;
    border-radius: 5px;
}

.video .text-save {
    /* font-size: 60px; */
    font-family: "Bangers";
    color: white;
    text-transform: uppercase;
    line-height: 0.9;
    padding-left: 400px;
    padding-bottom: 45px;
}

.nft-all {
    position: relative;
    margin-top: -95px;
    margin-bottom: -230px;
    z-index: 1111111;
}

.story {
    background-image: url("../images/story-bg.png");
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 100px;
    min-height: 100vh;
    box-sizing: content-box;
    z-index: 111111;
}

.story img {
    height: -webkit-fit-content;
}

@media (max-width: 900px) {
    .story {
        padding-top: 40px;
        /* padding-bottom: 30px;
        margin-bottom: 50px; */
        display: flex;
        align-items: center;
    }

    .video .description {
        /* font-size: 18px; */
        padding-top: 10px;
    }

    .video .text-save {
        /* font-size: 30px; */
        margin: auto;
        text-align: center;
        padding-left: 0;
    }

    .video .text-join {
        padding-left: 20px;
        /* font-size: 30px; */
    }

    .nft-all {
        object-fit: cover;
        margin-top: -60px;
    }
}

@media (max-width: 768px) {
    .nft-all {
        margin-bottom: -80px;
    }
}

@media (max-width: 500px) {
    .video .text-save {
        text-align: right;
    }
}

#trailer {
    z-index: 111111;
}

.story .title {
    font-size: 60px;
    font-family: "Bangers";
    color: rgb(255, 0, 254);
    text-transform: uppercase;
    text-align: right;
}

.story .content {
    font-size: 33px;
    font-family: "Nanum Pen";
    color: rgb(255, 255, 255);
    line-height: 1.5;
    text-align: right;
}

.story .bottom {
    font-size: 50px;
    font-family: "Delicious Handrawn";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    line-height: 1.2;
    text-align: right;
    margin-top: 60px;
}

.story>div {
    margin: auto;
}

.nft-avatar {
    background-color: rgb(255, 0, 254);
    background-size: cover;
    padding: 100px;
    padding-top: 200px;
    margin-top: -110px;
    display: flex;
    justify-content: space-evenly;
}

.nft-avatar .avatar {
    width: 30%;
    min-width: 230px;
}

@media (max-width: 768px) {
    .nft-avatar {
        padding: 50px;
        display: block;
    }

    .nft-avatar .avatar {
        margin: auto;
    }

    .story .title {
        font-size: 38px;
        text-align: center;
    }

    .story .content {
        text-align: center;
    }

    .story .bottom {
        text-align: center;
    }
}

.nft-avatar .word {
    position: absolute;
    top: -20px;
    left: -110px;
}

.nft-avatar .title {
    font-size: 60px;
    font-family: "Bangers";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    text-align: right;
}

.nft-avatar .btn-mint {
    font-size: 28px;
    width: 60%;
    margin: auto;
    font-family: "Delicious Handrawn";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    border-width: 2px;
    border-color: rgb(255, 255, 255);
    border-style: solid;
    border-radius: 2px;
    background-color: rgb(173, 59, 217);
}

.nft-avatar .content {
    font-size: 25px;
    font-family: "Nanum Pen";
    color: rgb(0, 0, 0);
    line-height: 1.2;
    margin-top: 20px;
}

.nft-avatar .attribute {
    font-size: 30px;
    font-family: "Delicious Handrawn";
    color: rgb(255, 255, 255);
    line-height: 1.333;
    margin-top: 20px;
}

.space-chicken {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin-top: -15vh;
    margin-bottom: -15vh;
}

.space-chicken .layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 111;
}

.space-chicken .spiral {
    position: absolute;
    left: calc(50% - 1250px);
    top: calc(50% - 1022px);
    width: 2500px;
    height: 2045px;
    max-width: unset;
}

@media (max-width: 1024px) {

    .space-chicken .layer,
    .space-chicken .bg,
    .space-chicken .spiral,
    .space-chicken .chicken {
        display: none;
    }
}

.space-chicken .bg-mid {
    display: none;
}

.bg-sm {
    display: none;
}

@media (max-width: 1024px) {
    .space-chicken .bg-mid {
        display: block;
    }
}

@media (max-width: 768px) {
    .space-chicken .bg-mid {
        display: none;
    }

    .space-chicken {
        margin-top: 0;
        margin-bottom: 0;
        height: unset;
    }

    .bg-sm {
        display: block;
    }
}

.space-chicken .chicken {
    position: absolute;
    left: calc(50% - 150px);
    top: calc(50% - 150px);
    width: 300px;
    z-index: 111;
}

.to-unknown {
    position: absolute;
    right: 100px;
    bottom: 140px;
    font-size: 40px;
    font-family: "Delicious Handrawn";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
}

.roadmap {
    background-image: url("../images/roadmap-bg.png");
    background-size: cover;
    padding: 50px;
    padding-top: 200px;
    padding-bottom: 160px;
    margin-top: -50px;
    z-index: -1;
}

.roadmap .head .title>div {
    position: absolute;
    z-index: 1111;
    top: -11px;
    font-size: 70px;
    left: 30px;
    font-family: "Bangers";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
}

.blackhole {
    /* background-image: url("../images/black-hole.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 80vh; */
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.blackhole .layer {
    position: absolute;
    left: 15px;
    top: -222px;
    width: 100%;
}

.blackhole .pilot {
    position: absolute;
    left: 34%;
    width: 75%;
    max-width: unset;
    top: 20px;
}

.blackhole .bg-mid {
    display: none;
}

@media (max-width: 1800px) {
    .blackhole .bg-mid {
        display: block;
    }

    .blackhole .bg-main,
    .blackhole .layer,
    .blackhole .pilot,
    .cable1,
    .cable2 {
        display: none;
    }
}

.cable1 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80%;
}

.cable2 {
    width: 70%;
    position: absolute;
    right: -8%;
    bottom: 0;
}

.phase1 .content,
.phase2 .content {
    position: relative;
    margin: auto;
    font-size: 40px;
    font-family: "Delicious Handrawn";
    color: rgb(255, 255, 255);
    line-height: 1.2;
    text-align: left;
    z-index: 111;
    max-width: 600px;
}

.phase1 .content>div,
.phase2 .content>div {
    padding: 40px;
    padding-top: 70px;
}

.phase1 .content .bio,
.phase2 .content .bio {
    font-size: 30px;
    font-family: "Delicious Handrawn";
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    text-align: center;
}

.phase1 .content>div,
.phase2 .content>div {
    font-size: 28px;
    font-family: "Delicious Handrawn";
    color: rgb(255, 255, 255);
}

.phase1 .title {
    position: absolute;
    /* font-weight: bolder; */
    right: 30px;
    z-index: 1111;
    top: -45px;
    left: -35px;
    text-shadow: 2px 2px 4px #000000;
    font-size: 60px;
    font-family: "Bangers";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    /* -webkit-text-stroke-color: #881191;
    -webkit-text-stroke-width: 2px; */
}

@media (max-width: 1023px) {
    .roadmap {
        padding: 10px;
        padding-top: 200px;
        padding-bottom: 160px;
    }
}

@media (max-width: 768px) {
    .phase1 .title {
        font-size: 30px;
        right: 0;
        top: -20px
    }

    /* .roadmap .content .bg {
        min-height: 500px;
        height: 58vh;
    } */

    .phase1 .content>div,
    .phase2 .content>div {
        padding: 20px;
        padding-top: 50px;
    }

    .phase1 .title img {
        width: 340px;
    }

    .phase1 .content {
        font-size: 22px;
    }

    .space-chicken .spiral {
        top: 20vh;
    }

}

/* @media (max-width: 466px) {
    .roadmap .content .bg {
        height: 77vh;
        min-height: 650px;
    }
} */

.phase2 .title {
    position: absolute;
    left: -20px;
    z-index: 1111;
    /* font-weight: bolder; */
    top: -45px;
    font-size: 60px;
    font-family: "Bangers";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    text-shadow: 2px 2px 4px #000000;
    /* -webkit-text-stroke-color: #1d6071; */
    /* -webkit-text-stroke-width: 2px; */
}

/* .phase2 .content {
    background-image: url("../images/phase2-bg.png");
    background-repeat: no-repeat;
    background-size: contain;
} */

.phase1 .title>div {
    position: absolute;
    top: 4px;
    left: 25px;
}

.phase1 .title span,
.phase2 .title span {
    font-family: "Delicious Handrawn";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    -webkit-text-stroke-width: 0;
    font-size: 35px;
}

.phase2 .title>div {
    position: absolute;
    top: 0px;
    left: 25px;
}

.phase2 .description {
    font-size: 35px;
    font-family: "Delicious Handrawn";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    text-align: center;
}

@media (max-width: 768px) {
    .phase2 .title {
        width: 280px;
        font-size: 30px;
        top: -20px;
    }

    .phase2 .description {
        font-size: 20px;
    }

    .phase1 .title span,
    .phase2 .title span {
        font-size: 24px;
    }

    .phase1 .title>div {
        top: 10px;
    }

    .phase2 .title>div {
        top: 6px;
    }

}

.phase1 .features {
    position: absolute;
    bottom: -60px;
    left: -20px;
}

.phase2 .features {
    position: absolute;
    bottom: -55px;
    left: -10px;
}

.roadmap-title {
    font-size: 80px;
    font-family: "Bangers";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    bottom: -60px;
    left: 200px;
    z-index: 111111;
    -webkit-text-stroke-color: #059bc2;
    -webkit-text-stroke-width: 3px;
}

.feature-content {
    margin-top: 30px;
    margin-left: 50%;
    font-size: 22px;
    font-family: "Delicious Handrawn";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .feature-content {
        margin-top: 55px;
    }

    .phase2 .features,
    .phase1 .features {
        left: -30px;
    }

    .feature-content {
        margin-top: 80px;
        margin-left: 0;
    }
}

.phase-description {
    font-size: 35px;
    font-family: "Delicious Handrawn";
    color: rgb(246, 216, 11);
    text-align: center;
}

.crew {
    position: relative;
    background-color: rgb(173, 59, 217);
    width: 100%;
    min-height: 100vh;
    margin-top: -50px;
}

.crew .top {
    position: absolute;
    top: -68px;
    right: 0;
    width: 0px;
    height: 0px;
    border-top: 70px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 98vw solid rgb(173, 59, 217);
}

.crew .title {
    text-align: center;
    position: absolute;
    z-index: 1111;
    top: -11px;
    font-size: 70px;
    left: 30px;
    font-family: "Bangers";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
}

.crew .bio {
    position: relative;
    margin-top: -25px;
}

.crew .bio img {
    position: absolute;
}

.crew .bio .name {
    position: relative;
    font-size: 26px;
    font-family: "Delicious Handrawn";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    padding-top: 16px;
    text-align: center;
}

.crew .bio .desc {
    font-size: 16px;
    position: relative;
    font-family: "Delicious Handrawn";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    text-align: center;
}

.crew .bio.blank {
    margin-bottom: 45px;
    position: absolute;
    width: 100%;
    bottom: -103px;
}

.ellipse {
    width: 230px;
    height: 230px;
}

.ellipse.add>a>img {
    border-radius: 50%;
    border: solid 8px white;
    box-sizing: border-box;
}

.ellipse.blank {
    border-width: 10px;
    border-color: rgb(255, 255, 255);
    border-style: solid;
    border-radius: 50%;
    background-color: rgb(255, 0, 254);
}

.crew .description {
    font-size: 20px;
    margin-top: 75px;
    font-family: "Delicious Handrawn";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    line-height: 2;
    text-align: center;
    width: 80%;
}

.contact {
    background-color: rgb(255, 0, 254);
    min-height: 100vh;
    display: flex;
}

.contact .content {
    max-width: 800px;
    padding: 50px;
    margin: auto;

}

.contact .content .title {
    font-size: 60px;
    font-family: "Bangers";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    text-align: center;
    padding-top: 25px;
}

.contact .content .description {
    font-size: 34px;
    font-family: "Delicious Handrawn";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    line-height: 2;
    text-align: center;
}

.input {
    border-width: 4px;
    border-color: rgb(173, 59, 217);
    border-style: solid;
    border-radius: 10px;
    font-family: "Delicious Handrawn";
    background-color: rgb(255, 241, 248);
    font-size: 30px;
    width: 100%;
    height: 60px;
    padding: 12px;
    margin-top: 30px;
}

textarea.input {
    height: 120px;
}

.contact .send {
    font-size: 30px;
    font-family: "Delicious Handrawn";
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    text-align: center;
    width: 200px;
    height: 50px;
    border-width: 2px;
    border-color: rgb(255, 255, 255);
    border-style: solid;
    background-color: rgb(173, 59, 217);
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: 100px;
}

.footer {
    font-size: 30px;
    font-family: "Roboto Condensed";
    color: rgb(158, 64, 209);
    text-transform: uppercase;
    text-align: center;
    padding: 30px;
}

@media (max-width: 768px) {
    .roadmap-title {
        font-size: 55px;
        left: 150px;
        bottom: -40px;
    }

    .contact .content {
        padding: 25px;
    }

    .blackhole {
        display: none;
        min-height: 500px;
    }

    .phase-description {
        font-size: 26px
    }

    .footer {
        font-size: 20px;
    }
}

@media (max-width: 540px) {
    .footer {
        font-size: 12px;
    }
}

.mint-container {
    background-image: url("../images/roadmap-bg.png");
    min-height: 100vh;
    background-size: cover;
}

.mint-page {
    max-width: 1280px;
}

.bg-pink {
    background-color: #FF00FE;
}

.text-color-yellow {
    color: #FFEA00;
}

.bg-purple {
    background-color: #846ADD;
}
.swiper-slide {
    width: 100% !important;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.btn-mint {
    left: 8.3%;
}